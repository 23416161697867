import { DefaultConfig } from './types';
import { defaultConfig } from './default';

export const defaultKin: DefaultConfig = {
  ...defaultConfig,
  brand: 'kin',
  membershipEnabled: true,
  medicareEnabled: true,
  syncConsultationsEnabled: true,
};
