import { defaultKin } from './default-kin';
import { sharedProdAuEnv } from './shared';
import { Config } from './types';

export const config: Config = {
  ...defaultKin,
  ...sharedProdAuEnv,
  environment: 'production',
  apiUrl: 'https://api.admin.au.eucalyptus.vc',
  restApiUrl: 'https://admin-api2.kinfertility.com.au',
  assetsUrl: 'https://assets.kinfertility.com.au',
  logoUrl: 'https://assets.kinfertility.com.au/kin-logo.svg',
  shopifyHostname: 'kin-fertility.myshopify.com',
  userAppUrl: 'https://kinfertility.com.au/app',
  doctorsAppUrl: 'https://doctors.kinfertility.com.au',
  googleOAuthClientId:
    '966685249037-gin9l5mhlahpk6eud426k1fu32ksnvm5.apps.googleusercontent.com',
  quizAdminUrl: 'https://quiz-admin.kinfertility.com.au',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee5',
};
